import APIServer from './APIServer'
//TODO: Status code validation + messages

export default class UserAPI extends APIServer { 

	constructor(auth=null) { 
		super(auth)
	}
	
	async sendAuth(mobile) {
		const mobileBody = "mobile=" + mobile
		console.log(mobileBody)
		return await super.nonJsonPost('/auth/mobile/', mobileBody)
	}

	async validateAuth(code) { 
		const tokenBody = "token=" + code
		return await super.nonJsonPost('/callback/auth/', tokenBody)
	}

	// Stop using this? Use constructor?
	setAuth(token) { 
		super.setAuth(token)
	}

	async sendPhoneToken(token, callback, errorcallback) { 
		const data = { token: token }
		return await super.post('/me/phone_token', data, callback, errorcallback)
	}

	async uploadProfilePicture(file) { 
		const url = "/me/image"
		const data = new FormData()
		data.append('photo', file)
		data.append('name', 'pic.jpg')
		return await super.uploadMedia(url, data)
	}

	getUserEventsChats(callback, errorcallback) { 
		super.get("/me/chats", callback, errorcallback)
	}


	async getMe() { 
		//TODO: Send auth somehow.
		return await super.get('/me')
	}

	async updateUser(userId, data, callback, errorcallback) { 
		return await super.patch(`/me/${userId}`, data)
	}

	async connectMailchimpAcc(code) { 
		return await super.get('/mailchimp_redirect?code=' + code)
	}

	async getMailchimpUrl() { 
		return await super.get('/mailchimp_url')
	}

	async getMailchimpLists() { 
		return await super.get('/mailchimp/lists')
	}

	async getPromoterPublicView(username) { 
		return await super.get('/promoter/' + username)
	}

	async updatePromoter(promoterId, body) { 
		return await super.patch('/promoter/update/' + promoterId, body)
	}

	async getPromoterAccess(promoterId) { 
		return await super.get('/access/promoter/' + promoterId.toString())
	}

	async updatePromoterAccess(accessId, accessLevel) {
		const data = {
			access_level: accessLevel
		} 
		return await super.patch('/access/' + accessId.toString(), data)
	}

	async deletePromoterAccess(accessId) { 
		const data = { 
			deleted_at: new Date()
		}
		return await super.patch('/access/' + accessId.toString(), data)
	}

	async createPromoterAccess(username, accessLevel) { 
		const data = { 
			username: username, 
			access_level: accessLevel
		}
		return await super.post('/access', data)
	}

	async connectStripeAcc(code) { 
	   const data = {code: code}
	   return await super.post('/connect_stripe', data)
    }

    async getStripeDashboard() { 
    	return await super.get('/stripe/dashboard')
    }

    async sendGroupMeToken(token) {
    	const body = {
    		access_token: token
    	} 
    	return await super.post('/groupme/user', body)
    }

    async getGroupMeGroups() { 
    	return await super.get('/groupme/groups')
    }

    async subscribe(email, city, listId=null) {
    	return await super.post('/subscribe', {email: email, city: city, list_id: listId});
    }
}